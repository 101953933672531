<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/doctors'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Doctor Full Name<strong>*</strong></label
                      >
                      <input
                        v-model="formData.fullName"
                        type="text"
                        class="form-control"
                        required
                        @input="updateSlug"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Slug <strong>*</strong></label>
                      <input
                        v-model="formData.slug"
                        type="text"
                        class="form-control"
                        disabled
                        readonly
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Instagram Url<strong>*</strong></label
                      >
                      <input
                        v-model="formData.instagram"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Facebook Url<strong>*</strong></label
                      >
                      <input
                        v-model="formData.facebook"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <b-form-group
                    class="mb-3"
                    id="example text"
                    label-cols-sm="3"
                    label-cols-lg="3"
                    label="years of professional experience"
                    label-for="text"
                  >
                    <input
                      v-model="formData.years"
                      type="text"
                      class="form-control"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    class="mb-3"
                    id="example text"
                    label-cols-sm="3"
                    label-cols-lg="3"
                    label="plastic surgeries during his career"
                    label-for="text"
                  >
                    <input
                      v-model="formData.surgery"
                      type="text"
                      class="form-control"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    class="mb-3"
                    id="example text"
                    label-cols-sm="3"
                    label-cols-lg="3"
                    label="to listen his message to you"
                    label-for="text"
                  >
                    <input
                      type="file"
                      class="form-control"
                      @change="fileChange('video', $event)"
                      required
                    />
                  </b-form-group>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Doctors Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Doctors Create",
      items: [
        {
          text: "Doctors",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        fullName: null,
        slug: null,
        instagram: null,
        facebook: null,
        years: null,
        career: null,
        surgery: null,
        video: null,
      },
      value1: null,
      options: [],
      cats: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    updateSlug() {
      const turkishCharacters = {
        ı: "i",
        İ: "i",
        ü: "u",
        Ü: "u",
        ö: "o",
        Ö: "o",
        ç: "c",
        Ç: "c",
        ş: "s",
        Ş: "s",
        ğ: "g",
        Ğ: "g",
        " ": "-",
      };

      let updatedSlug = this.formData.fullName.toLowerCase();

      updatedSlug = updatedSlug.replace(
        /[ıİüÜöÖçÇşŞğĞ]/g,
        (match) => turkishCharacters[match] || match
      );
      updatedSlug = updatedSlug.replace(/[^a-z0-9ığüşöç\s-]/g, "");

      updatedSlug = updatedSlug.replace(/\s+/g, "-");

      this.formData.slug = updatedSlug;
    },
    async FindLp() {
      await API.Get(API.Category.CategoryList).then((response) => {
        if (response.data.status == "success") {
          this.cats = response.data.order;
        } else {
          this.cats = [];
        }
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Doctors.Create,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/doctors");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
